body {
  background: rgba(26, 26, 26, 0.76);
  font-family: FK Roman Text,Times New Roman,sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.container-image {
  background: rgba(26, 26, 26, 0.76);
  background-image:
          linear-gradient(10deg, rgba(255, 255, 255, 0.42), rgba(0, 0, 0, 0.91)),
          url("./images/17.webp");
  background-size: cover;
  background-repeat:   no-repeat;
  background-position: center center;
}
